<template>
  <b-container fluid class="overflow-auto">
    <!-- Publisher Interface controls -->
    <h5 class="my-3"><strong>PUBLISHERS</strong></h5>
    <a-row class="mb--1">
      <a-checkbox v-model="showActive">
        Show only active
      </a-checkbox>
    </a-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'xml-publishers'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'xml-publishers-columns'" :options="fields"></columnSelect>
          <CreateButton module="xml" target="publisher" />
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :small="true"
      :bordered="true"
      :striped="true"
      :hover="true"
      show-empty
      stacked="md"
      :items="showPublishers"
      :fields="filteredFields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :tbody-tr-class="rowClass"
      @filtered="onFiltered"
    >
      <template #cell(feeds)="data">
       <div v-if="data.item.PubfeedsCount>1">
          <b-link :to="'publisher/' + data.item.Id + '/feeds'" @click="showFeeds(data.item, data.item.PubfeedsCount)" style="color:rgb(105, 151, 244)">{{ data.item.PubfeedsCount }} feeds</b-link>
       </div>
        <div v-else-if="data.item.PubfeedsCount === 1">
          <b-link :to="'publisher/' + data.item.Id + '/feeds'" @click="showFeeds(data.item, data.item.PubfeedsCount)" style="color:rgb(105, 151, 244)">{{ data.item.PubfeedsCount }} feed</b-link>
        </div>
        <div v-else-if="data.item.Status === 0">
          <!-- <b-link :to="'/xml/feed/create/' + data.item.Id" @click="showFeeds(data.item, data.item.PubfeedsCount)" style="color:rgb(105, 151, 244)">Create feed</b-link> -->
          <!-- <b-link :to="{ name: 'xml-create-feed-publisher', params: { id: data.item.Id } }" @click="showFeeds(data.item, data.item.PubfeedsCount)" style="color:rgb(105, 151, 244)"> Create feed</b-link> -->
          <b-link :to="redirectTo('xml-feed-create-publisher', { publisherId: data.item.Id })" @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">Create Feed</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="togglePublisherStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Country)="data">
        <div class="items-center"><img :src="matchIcon(countries, data.item.Country)" /> {{ $store.getters['info/countryName'](data.item.Country) }}</div>
      </template>
      <template #cell(Id)="data">
        <div class="items-center">{{ data.item.Id }}</div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">{{ data.item.Name }}</div>
      </template>
      <template #cell(Description)="data">
        <div class="items-center">{{ data.item.Description }}</div>
      </template>
      <template #cell(Phone)="data">
        <div class="items-center">{{ data.item.Phone }}</div>
      </template>
      <template #cell(Skype)="data">
        <div class="items-center">{{ data.item.Skype }}</div>
      </template>
      <template #cell(Website)="data">
        <div class="items-center">{{ data.item.Website }}</div>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop :to="{ name: 'xml-publisher-update', params: { id: data.item.Id }  }">
          <b-button
            size="sm"
            class="btn-info mr-1"
            @click="editPublisher(data.item.Id)"
          >
            <i class="fa fa-edit"></i>
          </b-button>
        </router-link>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import staticCountryData from '@/resources/countries.json'

export default {
  components: {
    columnSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      countries: staticCountryData,
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          headerTitle: 'ID',
          sortable: true,
          thStyle: 'width:50px',
          tdClass: 'text-right',
        },
        {
          key: 'Mail',
          label: 'Email',
          show: false,
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'Username',
          label: 'Username',
          show: true,
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: true,
          sortable: true,
        },
        {
          key: 'feeds',
          label: 'Feeds',
          headerTitle: 'Feeds',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'Website',
          label: 'Website',
          show: true,
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'Country',
          label: 'Country',
          show: true,
          sortable: true,
          thStyle: 'width:160px',
        },
        {
          key: 'StatsAuth',
          label: 'Stats Auth',
          headerTitle: 'Stats API endpoint authentication code',
          show: false,
          sortable: true,
          thStyle: 'width:110px',
        },
        {
          key: 'City',
          label: 'City',
          show: false,
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'ZipCode',
          label: 'Zip Code',
          show: false,
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'Address',
          label: 'Address',
          show: false,
          sortable: true,
          thStyle: 'width:200px',
        },
        {
          key: 'Phone',
          label: 'Phone',
          show: true,
          sortable: true,
          thStyle: 'width:140px',
        },
        {
          key: 'Skype',
          label: 'Skype',
          show: true,
          sortable: true,
          thStyle: 'width:130px',
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },

      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['info', 'publisher', 'settings']),
    ...mapGetters('XMLpublisher', ['getPublishers', 'getActivePublishers']),
    showActive: {
      get: function () {
        return this.settings.showActivePublishers
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActivePublishers', value })
      },
    },
    showPublishers() {
      return this.showActive ? this.getActivePublishers : this.getPublishers
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showPublishers.length
    },
  },
  methods: {
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    showFeeds(publisher, feedsCount) {
      if (feedsCount > 0) {
        // this.$router.push('/xml/publisher/' + publisher.Id + '/feeds')
        this.$router.push({ name: 'xml-publisher-feeds', params: { id: publisher.Id } }).catch(() => {})
      } else {
        // this.$router.push('/xml/feed/create/' + publisher.Id)
        this.$router.push({ name: 'xml-feed-create-publisher', params: { publisherId: publisher.Id } }).catch(() => {})
      }
    },
    redirectTo(route, data = null) {
      return this.$router.resolve({ name: route, params: data }).resolved.path
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editPublisher(id) {
      // this.$router.push('/xml/publisher/update/' + id)
      this.$router.push({ name: 'xml-publisher-update', params: { id: id } }).catch(() => {})
    },
    togglePublisherStatus(publisher) {
      const status = publisher.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change publisher status?',
        content: h => <div>{status} publisher <strong>{publisher.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = publisher.Status === 0 ? 1 : 0
          this.$store.dispatch('XMLpublisher/CHANGE_STATUS', {
            id: publisher.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.$store.dispatch('XMLpublisher/LOAD_PUBLISHERS')
  },
}
</script>
